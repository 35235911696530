import WebViewer, {WebViewerInstance} from '@pdftron/webviewer'
import React, {FC} from 'react'
// import {Select, MenuItem, FormControl} from '@material-ui/core'
import {
  flowMax,
  addDisplayName,
  addRef,
  addState,
  addEffect,
  addHandlers,
  addWrapper,
  addProps,
  addStateHandlers,
} from 'ad-hok'
import {Modal, Card, CardContent} from '@material-ui/core'
import {branchIfFalsy, branchIfTruthy} from 'ad-hok-utils'

import {makeClasses, addClasses} from 'theme'
import typedAs from 'utils/typedAs'
import Body1 from 'components/Body1'
import {FileTemplates_fileTemplates} from 'graphql/deserializedTypes/FileTemplates'
import {
  addUpdateEditableFileMutation,
  addMarkReceivedWebformFileTemplateClearedMutation,
} from 'graphql/generated'
import Button from 'components/Button'
import {addTranslationHelpers} from 'utils/i18n'
import {EditableFileFields} from 'graphql/deserializedTypes/EditableFileFields'
import addFetchSignedDocumentUploadUrl from 'utils/addFetchSignedDocumentUploadUrl'
import {EditableFileStatus} from 'graphql/deserializedTypes/globalTypes'
import Tooltip from 'components/Tooltip'
import {getPdftronLicenseKey} from 'utils/pdftron'
import {PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates} from 'graphql/deserializedTypes/PersonReceivedWebformFileTemplates'
import {addIsPatientPortal} from 'utils/patientPortal'
import {makeEditableFileKey} from 'utils/editableFiles'
import {Applications_applications} from 'graphql/deserializedTypes/Applications'
import {AccountAudits_accountAudits_application} from 'graphql/deserializedTypes/AccountAudits'

const classes = makeClasses((theme) => ({
  backdrop: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
  },
  card: {
    pointerEvents: 'auto',
    width: 'calc(100% - 100px)',
    height: 'calc(100% - 100px)',
    display: 'flex',
  },
  contents: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  pdfContainer: {
    flex: 1,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonSubContainer: {
    display: 'flex',
  },
  outForSigning: {
    textAlign: 'center',
  },
  deleteButtonWrapper: {
    marginRight: 'auto',
  },
  discardButton: {
    marginRight: 'auto',
  },
  phoneNumbersContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginRight: 'auto',
  },
  sendToCellForSigningButton: {
    marginLeft: theme.spacing(1),
  },
}))

type AddTooltipIfDisabled = <
  TProps extends {
    disabled?: boolean
  }
>(
  props: TProps
) => TProps

const addTooltipIfDisabled: AddTooltipIfDisabled = flowMax(
  addTranslationHelpers,
  addWrapper((render, {disabled, t}) =>
    disabled ? (
      <Tooltip title={t('editPdfDialog.saveInProgress')}>
        <div>{render()}</div>
      </Tooltip>
    ) : (
      <>{render()}</>
    )
  )
)

const getBlobFromPdf = async (instance: WebViewerInstance) => {
  const {documentViewer, annotationManager} = instance.Core

  const document = documentViewer.getDocument()
  const xfdfString = await annotationManager.exportAnnotations()

  const data = await document.getFileData({xfdfString})

  return new Blob([new Uint8Array(data)], {type: 'application/pdf'})
}

type AddSetReadonlyOnMountType = <
  TProps extends {
    instance: WebViewerInstance | null
    file: FileTemplateWithSignedUrl | EditableFileWithSignedUrl
  }
>(
  props: TProps
) => TProps

const addSetReadonlyOnMount: AddSetReadonlyOnMountType = addEffect(
  ({instance}) => () => {
    if (!instance) {
      return
    }

    const {annotationManager, Annotations} = instance.Core

    const readonly = true
    if (readonly) {
      annotationManager.enableReadOnlyMode()

      // https://www.pdftron.com/documentation/web/guides/forms/modify-fields/
      annotationManager.addEventListener(
        'annotationChanged',
        (annotations, action, {imported}) => {
          if (imported && action === 'add') {
            annotations.forEach((annot: any) => {
              if (annot instanceof Annotations.WidgetAnnotation) {
                annot.fieldFlags.set('ReadOnly', true)
              }
            })
          }
        }
      )
    }
  },
  ['instance', 'file']
)

export interface FileTemplateWithSignedUrl extends FileTemplates_fileTemplates {
  signedUrl: string
}

export interface EditableFileWithSignedUrl extends EditableFileFields {
  signedUrl: string
}

const isEditableFile = (
  value: FileTemplateWithSignedUrl | EditableFileWithSignedUrl
): value is EditableFileWithSignedUrl => value['__typename'] === 'EditableFile'

const isFileTemplate = (
  value: FileTemplateWithSignedUrl | EditableFileWithSignedUrl
): value is FileTemplateWithSignedUrl => value['__typename'] === 'FileTemplate'

interface CancelButtonProps {
  onClick: () => void
  disabled?: boolean
}

const CancelButton: FC<CancelButtonProps> = flowMax(
  addDisplayName('CancelButton'),
  addIsPatientPortal,
  branchIfTruthy('isPatientPortal'),
  addTooltipIfDisabled,
  addTranslationHelpers,
  ({onClick, disabled, t}) => (
    <Button onClick={onClick} color="secondary" disabled={disabled}>
      {t('editPdfDialog.cancel')}
    </Button>
  )
)

type ReceivedWebformFileTemplateType = PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates

interface Props {
  personId: string
  applicationId?: string
  file: FileTemplateWithSignedUrl | EditableFileWithSignedUrl | null
  onClose: () => void
  receivedWebformFileTemplate?: ReceivedWebformFileTemplateType | null
  onDelete?: () => void
  disallowClosingWithoutSave?: boolean
  title?: string
  application?:
    | Applications_applications
    | AccountAudits_accountAudits_application
}

const ReadOnlyPdfDialog: FC<Props> = flowMax(
  addDisplayName('EditPdfDialog'),
  addWrapper((render, {file, onClose, disallowClosingWithoutSave}) => (
    <Modal
      open={!!file}
      onClose={disallowClosingWithoutSave ? () => {} : onClose}
    >
      <>{render()}</>
    </Modal>
  )),
  branchIfFalsy('file'),
  addRef('viewerRef', typedAs<HTMLDivElement | null>(null)),
  addState('instance', 'setInstance', typedAs<WebViewerInstance | null>(null)),
  addEffect(
    ({file: {signedUrl}, viewerRef, setInstance}) => () => {
      async function initWebViewer() {
        const response = await fetch(decodeURIComponent(signedUrl))
        const contentType = response.headers.get('content-type')
        const extension =
          contentType === 'image/jpeg'
            ? 'jpeg'
            : contentType === 'image/png'
            ? 'png'
            : 'pdf'
        const webViewer = await WebViewer(
          {
            path: '/assets/webviewer/lib',
            initialDoc: decodeURIComponent(signedUrl),
            extension,
            licenseKey: getPdftronLicenseKey(),
          },
          viewerRef.current!
        )
        setInstance(webViewer)
      }
      initWebViewer()
    },
    ['signedUrl']
  ),
  addSetReadonlyOnMount,
  addUpdateEditableFileMutation({}),
  addFetchSignedDocumentUploadUrl,
  addMarkReceivedWebformFileTemplateClearedMutation({}),
  addHandlers({
    markCleared: ({
      receivedWebformFileTemplate,
      mutateMarkReceivedWebformFileTemplateCleared,
    }) => () => {
      if (!receivedWebformFileTemplate) return
      mutateMarkReceivedWebformFileTemplateCleared({
        variables: {
          id: receivedWebformFileTemplate.id,
        },
        optimisticResponse: {
          markReceivedWebformFileTemplateCleared: {
            __typename: 'ReceivedWebformFileTemplate',
            id: receivedWebformFileTemplate.id,
            cleared: true,
          },
        },
      })
    },
  }),
  addStateHandlers(
    {
      isSaving: false,
    },
    {
      onSaving: () => () => ({
        isSaving: true,
      }),
      onNoLongerSaving: () => () => ({
        isSaving: false,
      }),
    }
  ),
  addIsPatientPortal,
  addTranslationHelpers,
  addProps(({file, title}) => ({
    name: title ?? (isEditableFile(file) ? file.filename : file.name),
    editableFile: isEditableFile(file) ? file : undefined,
    outForSigning:
      isEditableFile(file) && file.status === EditableFileStatus.outForSigning,
    sendDocumentType:
      (isEditableFile(file) || isFileTemplate(file)) && file.documentSendType
        ? file.documentSendType
        : undefined,
    isEsignDocument:
      (isEditableFile(file) || isFileTemplate(file)) &&
      file.documentSendType === 'eSignDocument'
        ? true
        : false,
    isDocumentSendAndSave:
      (isEditableFile(file) || isFileTemplate(file)) &&
      file.documentSendType === 'editAndSendDocument'
        ? true
        : false,
    isDocumentSendOnly:
      isFileTemplate(file) && file.documentSendType === 'sendDocument'
        ? true
        : false,
    isFaxableDocument:
      isFileTemplate(file) && file.documentSendType?.includes('FINAL_')
        ? true
        : false,
  })),
  addHandlers({
    onSave: ({
      file,
      personId,
      applicationId,
      // mutateCreateEditableFile,
      mutateUpdateEditableFile,
      onClose,
      fetchSignedDocumentUploadUrl,
      instance,
      markCleared,
      onSaving,
      onNoLongerSaving,
      isPatientPortal,
      sendDocumentType,
      isDocumentSendOnly,
      isDocumentSendAndSave,
      isEsignDocument,
      t,
    }) => async ({
      sendForSigning,
      sendForRemoteSigningSessionId,
    }: {
      sendForSigning: boolean
      sendForRemoteSigningSessionId?: string
    }) => {
      if (!instance) {
        return
      }

      onSaving()
      try {
        const blob = await getBlobFromPdf(instance)
        const fileKey = makeEditableFileKey(personId)
        const url = await fetchSignedDocumentUploadUrl(fileKey)

        const response = await fetch(url, {method: 'PUT', body: blob})
        if (!response.ok) {
          throw new Error('S3 upload failed')
        }

        if (isEditableFile(file)) {
          await mutateUpdateEditableFile({
            variables: {
              editableFile: {
                id: file.id,
                extension: 'pdf',
                fileKey,
                ...(isPatientPortal
                  ? {
                      status: EditableFileStatus.returned,
                    }
                  : {}),
              },
              applicationId,
              sendForSigning,
              sendForRemoteSigningSessionId,
              markUnreviewed: isPatientPortal,
              sendDocumentType,
              isDocumentSendOnly,
              isDocumentSendAndSave,
              isEsignDocument,
            },
          })
        }
        markCleared()
        onClose()
        onNoLongerSaving()
      } catch (e) {
        onNoLongerSaving()
        window.alert(t('editPdfDialog.failedToSave'))
      }
    },
    onDiscardClick: ({markCleared, onClose}) => () => {
      markCleared()
      onClose()
    },
  }),
  addProps(({isSaving}) => ({
    areActionButtonsDisabled: isSaving,
  })),

  addClasses(classes),
  ({
    name,
    // editableFile,
    viewerRef,
    onClose,
    // onSave,
    areActionButtonsDisabled,
    classes,
    // application,
  }) => (
    <div className={classes.backdrop}>
      <Card className={classes.card}>
        <CardContent className={classes.contents}>
          <Body1>{name}</Body1>
          <div ref={viewerRef} className={classes.pdfContainer} />
          <div className={classes.buttonContainer}>
            <div className={classes.buttonSubContainer}>
              {/* SendDocumentForRemoteSigningButton Goes Here */}
            </div>

            <div className={classes.buttonSubContainer}>
              <CancelButton
                onClick={onClose}
                disabled={areActionButtonsDisabled}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  )
)

export default ReadOnlyPdfDialog
